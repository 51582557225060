/* App.css */

/* Base styles for larger screens (laptop/desktop) */
.desktopHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f5f5f5;
  position: fixed;
  /* Add relative positioning for absolute elements */
  top: 0;
  z-index: 9;
}

.mainheader {
  display: none;
}

.leftHeader,
.rightHeader {
  display: flex;
  align-items: center;
}

.leftHeader>div,
.rightHeader>div {
  margin-right: 1rem;
}

.rightHeaderDesktop {
  width: 45%;
  display: flex;
  justify-content: space-evenly;
  /* background: #000; */
}

.leftHeaderDesktop {
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-between;
}

.inputDivision {
  background-color: #fff;
  display: flex;
  align-items: center;
  /* border: 1px solid #ce1111; */
  border-radius: 0.3rem;
  padding: 0.1rem;
}

.inputDivisionDesktop {
  width: 55%;
  height: 2.5rem;
  background: gainsboro;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.inputDivisionDesktop span {
  width: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.inputDivisionDesktop input {
  width: 90%;
  height: 70%;
  background-color: transparent;
  outline: none;
  border: 0;
}

.accountpiecesDesk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inputDivision input {
  border: none;
  outline: none;
  padding: 0.5rem;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.accountpiecesDesktext {
  font-size: 0.7rem;
  color: #fff;
  font-weight: 600;
}

.accountpiecesDeskicon {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
}

/* Menu toggle button styles */
.menuToggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
}

.drawer {
  display: none;
}

.rightHeaderDesktop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.homebannerimgs1 {
  margin-top: 4%;
}

/* Mobile view (max-width: 600px) */
@media (max-width: 780px) {
  .desktopHeader {
    display: none;
  }

  .mainheader {
    height: 3.5rem !important;
    display: flex;
    justify-content: space-between;
    padding:1.3rem;
    align-items: center;
    background-color: #f5f5f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* margin-top: 30px; */
    z-index: 9;
  }

  .mainheader {
    /* padding: 1rem; */
  }

  .homebannerimgs {
    margin-top: 19%;
  }

  .leftHeader {
    display: none;
    /* Hide original leftHeader on mobile */
  }

  .menuToggle {
    display: block;
    /* Show menu toggle button */
  }

  .rightHeaderDesktop {
    display: flex;
  }

  .rightHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* code rama */
  .categorynametext1 {
    font-size: 0.9rem !important;
    /* height: 100vh !important; */
  }

  /* end-- */

  .rightHeader>div {
    margin-right: 0.5rem;
  }

  .inputDivision {
    width: 100%;
  }

  .inputDivision input {
    width: 240px;
    height: 15px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  /* Drawer styles */
  .drawer {
    display: block;
    position: absolute;
    top: -25px;
    left: -24px;
    width: 80%;
    height: 100vh !important;
    /* background-color: #fff; */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    padding: 1.5rem;
    box-sizing: border-box;
  }

  .drawer.open {
    transform: translateX(0);
  }

  .open {
    display: "none";
  }

  .drawer {
    padding: 0;
    padding-left: 24px;
    padding-top: 24px;
  }

  .drawerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .increasebackgroundcolor {
    height: 100vh;
    background-color: #fff;
  }

  .drawer div {
    padding: 0.5rem 0;
    cursor: pointer;
  }
}

/* Tablet view (max-width: 1024px) */
@media screen and (min-width: 780px) and (max-width: 1024px) {
  .mobileversion {
    display: none;
  }

  .mainheader {
    display: none !important;
  }

  .leftHeader>div,
  .rightHeader>div {
    margin-right: 1rem;
  }

  .inputDivision input {
    width: auto;
  }
}