* {
  padding: 0;
  margin: 0;
}
#Main {
  display: none;
}
@media screen and (min-width: 280px) and (max-width: 450px) {
  * {
    padding: 0;
    margin: 0;
  }
  #Main {
    width: 100%;
    /* border: lid red solid red; */
    background-color: white;
    display: flex;
    flex-direction: column;
    display: block;
  }
  /* _______________________Header_________________________ */
  .Header {
    top: 0;
    position: fixed;
    border: 1px solid #fff;
    width: 100%;
    z-index: 99999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
  }
  .subhead {
    /* border: lid red solid yellow; */
    width: 95%;
    display: flex;
    justify-content: flex-start;
  }
  .head1 {
    width: 45%;
    /* border: lid red solid red; */
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    align-items: center;
  }
  .head1 > h6 {
    font-size: 1rem;
    color: #424553;
  }
  .Icon {
    /* border: lid red solid green; */
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    color: #424553;
  }
  .Icon1 {
    /* border: lid red solid green; */
    justify-content: center;
    font-size: 1.3rem;
    color: #424553;
  }
  .Text {
    margin-top: 0.4rem;
    font-size: 1rem;
  }
  .head2 {
    width: 55%;
    /* border: lid red solid red; */
  }
  .head2 > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.45rem;
  }
  .head2 > ul > li {
    /* border: lid red solid red; */
    align-items: center;
  }

  /* _______________________Mainbody_____________________________________ */
  .Mainbody {
    width: 100%;
    border-radius: 2rem;
  }
  .Images {
    width: 100%;
    background-color: #fff;
    margin-top: 0.5rem;
    /* display: flex; */
    /* flex-direction: column; */
  }
  .Dress {
    height: 100%;
    width: 100%;
  }
  .Dress img {
    /* border: 1px solid rgb(73, 73, 73); */
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
  .dressname {
    /* border: lid red solid red; */
    padding: 0.8rem;
  }
  .name {
    margin-top: 0.5rem;
    width: 90%;
  }
  .name > span {
    /* background-color: #e02020; */
    font-size: 1rem;
    color: #262a39;
    /* font-weight: 500; */
  }
  .price {
    /* margin-bottom: 0.3rem; */
    /* border: lid red solid green; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .mrp {
    font-size: 1rem;
    color: #93959e;
  }
  .mrp1 {
    font-size: 1rem;
    color: #93959e;
    text-decoration: line-through;
  }
  .cost {
    font-size: 1.2rem;
    color: #171a27;
    font-weight: 600;
  }
  #rups {
    font-size: 1.2rem;
    color: #171a27;
    font-weight: 400;
  }
  .discount {
    /* border: lid red solid red; */
    font-size: 1rem;
    color: #f18d13;
    /* align-items: center; */
    font-weight: 700;
  }
  .BestPrice {
    margin-top: -1.1rem;
    padding: 0.8rem;
    /* border: lid red solid red; */
    display: flex;
  }
  .subpart {
    width: 100%;
    background-color: #edfff8;
    border-radius: 1.2rem;
    /* border: lid red solid blue; */
    padding: 0.8rem;
  }
  .best-part1 {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(22, 75, 53);
  }
  .rightarrow {
    /* border: lid red solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 300;
    color: #ff3e6c;
  }
  .bold {
    font-weight: 700;
    color: rgb(22, 75, 53);
  }
  .offer {
    font-weight: 700;
    color: rgb(22, 75, 53);
  }

  .best-part2 {
    font-size: 0.88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(22, 75, 53);
  }
  .bag {
    /* border: lid red solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #shoppingbag {
    /* border: lid red solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }
  #costt {
    font-size: 0.85rem;
    /* background-color: #f18d13; */
  }
  #zero {
    font-weight: 500;
  }
  .save {
    color: rgb(38, 42, 57);
    font-size: 0.8rem;
  }
  /*__________________ Bank Offer_________________________ */
  .Offers {
    padding: 0.8rem;
    margin-top: 0.5rem;
    /* border: 1px solid rgb(17, 62, 198); */
  }
  .subOffer {
    padding: 0.8rem;
    border: 1px solid rgb(212, 213, 216);
    border-radius: 1rem;
  }
  .off1 {
    /* border: 2px solid red; */
    color: rgb(38, 42, 57);
    font-size: 1.2rem;
    font-weight: 700;
  }
  .off2 {
    /* border: 2px solid green; */
    margin-top: 0.5rem;
    display: flex;
    gap: 0.8rem;
  }
  .bankOffer {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    /* gap: 0.2rem; */
  }
  .bankOffer > h5 {
    font-size: 1rem;
    color: rgb(38, 42, 57);
    font-weight: 600;
    margin-top: 0.2rem;
  }
  .bankOffer > span {
    font-size: 0.86rem;
    font-weight: 400;
    color: #686b77;
  }
  .bankOffer > h6 {
    font-size: 0.85rem;
    color: rgb(255, 63, 108);
    font-weight: 700;
    margin-top: 0.5rem;
  }
  /* ______________________check delivery_______________________________ */

  .CheckDelivery {
    /* border: 2px solid red; */
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  #check {
    /* border: 2px solid blue; */
    font-size: 1.2rem;
    font-weight: 640;
    color: #262a39;
  }
  .Input > input {
    border: 1px solid #bfc0c6;
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.7rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .Input > Input::placeholder {
    color: #ff3e6c;
  }

  .delivery {
    /* border: 2px solid green; */
    padding: 0.3rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  .expressdel {
    width: 90%;
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center;  */
    flex-direction: row;
    gap: 0.6rem;
  }
  .pay {
    width: 90%;
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    flex-direction: row;
    gap: 0.6rem;
  }
  .free7 {
    /* margin-left: 0.8rem; */
    /* width: 95%; */
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    gap: 0.6rem;
  }
  .lighter {
    font-size: 0.96rem;
    color: #454546;
    font-weight: 500;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid yellow; */
    font-size: 1.2rem;
  }
  .boldtext {
    font-size: 1rem;
    font-weight: 650;
  }
  /* ________________________design______________________ */
  .Design {
    /* border: 2px solid red; */
    padding: 0.8rem;
  }
  .subdesign {
    border: 1px solid #bfc0c6;
    border-radius: 1.5rem;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .designs {
    width: 100%;
    /* background-color: #03a685; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

  }
  .design1 {
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .design2 {
    /* border: 2px solid red; */
    width: 46%;
  }
  .des {
    /* border: 2px solid red; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .des > h5 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.88rem;
    text-transform: capitalize;
    font-weight: 600;
    /* text-transform: uppercase; */
    /* font-weight: 650; */
    color: #262a39;
    margin:  1vh 0 0 0 !important;
  }
  .des > p {
    /* border: 2px solid green; */
    margin-top: -0.4rem;
    font-size: 0.88rem;
    font-weight: 450;
    color: #858586;
    margin-top: 0.1vh;
  }
  #more {
    font-size: 1rem;
    color: #ff2459;
  }
  /* ......product details..... */
  .Productdetails {
    margin-top: 0.7rem;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
  }
  .product > h5 {
    font-size: 1rem;
    font-weight: 650;
    color: #262a39;
  }
  .product > p {
    font-size: 0.9rem;
    font-weight: 400;
    /* color: #636363; */
  }
  .kurtadesign {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center;
      align-items: center; */
  }
  .kurtadesign > h5 {
    font-size: 1rem;
    font-weight: 650;
    color: #262a39;
  }
  .list {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .list > ul {
    width: 92%;
    /* background-color: #be1919; */
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .palazzosdesign {
    /* border: 2px solid; */
    display: flex;
    flex-direction: column;
  }
  .palazzosdesign > h5 {
    font-size: 1rem;
    font-weight: 650;
    color: #262a39;
  }
  .size-fit > h5 {
    font-size: 1rem;
    font-weight: 650;
    color: #262a39;
  }
  .size-fit > p {
    font-size: 0.9rem;
    font-weight: 450;
  }
  .material > h5 {
    font-size: 1rem;
    font-weight: 650;
    color: #262a39;
  }
  .material > p {
    font-size: 0.9rem;
    font-weight: 450;
  }

  /* ___________________SelectSizes_______________________ */
  .SelectSizes {
    /* margin-top: 1rem; */
    /* border: 2px solid yellow; */
    padding: 0.8rem;
  }
  .subselect {
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
  }
  .sizes {
    /* background-color: #f18d13; */
    color: rgb(23, 26, 39);
    font-size: 1.2rem;
    font-weight: 600;
  }
  .sizeChart {
    width: 6.2rem;
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
  }

  .rightarrow {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff3e6c;
    font-weight: 300;
    /* font-size: rem; */
  }
  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: #ff3e6c;
    font-weight: 650;
  }
  .flow {
    display: flex;
    justify-content: space-between;
  }
  .sizeflow {
    margin-top: 1rem;
    /* border: 2px solid rgb(219, 79, 14); */
    display: flex;
    justify-content: space-between;
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }
  .circle {
    /* width: 3.2rem; */
    height: 3.2rem;
    border-radius: 1rem;
    border: 1px solid #bfc0c6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    min-width: 3.2rem;
  }
  .choose-size {
    font-size: 0.97rem;
    color: #262a39;
    font-weight: 500;
  }

  /* ________________Quality________________________ */
  .Quality {
    width: 100%;
    margin-top: 0.5rem;
    /* border: 2px solid green; */
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subquality {
    width: 80%;
    /* background-color: #f18d13; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .genuineProduct {
    /* border: 2px solid red; */
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
  }
  .genuineProduct > img {
    /* background-color: #03a685; */
    /* border: 2px solid blue; */
    width: 43%;
    /* margin-top: 0.4rem; */
  }
  .genuineProduct > h5 {
    /* border: 2px solid red; */
    width: 70%;
    text-align: center;
    font-size: 1rem;
    width: 700;
    margin-top: 0.4rem;
    color: #ff3e6c;
  }
  .QualityChecked {
    /* border: 2px solid red; */
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
  }
  .QualityChecked > img {
    /* background-color: #03a685; */
    /* border: 2px solid blue; */
    width: 48%;
  }
  .QualityChecked > h5 {
    /* border: 2px solid red; */
    width: 70%;
    text-align: center;
    font-size: 1rem;
    width: 700;
    color: #ff3e6c;
  }
  /*____________________ Accordian___________________ */
  .Accordian {
    padding: 0.8rem;
    /* border: 2px solid blue; */
  }
  .Questions {
    border: 1px solid #bfc0c6;
    border-radius: 1.4rem;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
  }
  .que {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .que > h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.4rem;
    color: #262a39;
  }

  /* _____________more information______________ */
  .moreinfo {
    margin-top: 0.8rem;
    /* border: 2px solid red; */
  }
  .subinfo {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  .info1 > h4 {
    font-size: 1rem;
    font-weight: 650;
  }
  .info2 > span {
    font-size: 1rem;
    color: #535766;
    font-weight: 500;
  }
  .view {
    font-size: 1rem;
    font-weight: 600;
    color: #ff3e6c;
  }
  .info4 {
    padding: 0.8rem;
    /* border: 2px solid greenyellow; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  #arrow {
    /* border: 2px solid red; */
    font-size: 1.1rem;
    /* font-weight:100; */
    color: #3e4152;
  }
  #online {
    font-size: 0.95rem;
  }

  /* customers reviews....................... */
  .Customers {
    background-color: #fff;
    border: lid red solid red;
    padding: 0.8rem;
  }
  .Customers > h5 {
    font-size: 0.95rem;
    color: #3e4152;
  }
  .review1 {
    border: lid red solid green;
  }
  .review1 > p {
    font-size: 1rem;
  }
  .line {
    /* border: lid red solid yellow; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.6rem;
  }
  .percentage {
    font-size: 0.71rem;
    color: #3e4152;
  }
  .review-bar {
    width: 8.75rem;
    height: 0.3rem;
    /* border-radius: 0.5rem; */
    background-color: #eaeaec;
  }
  .percentage1 {
    font-size: 0.71rem;
    font-weight: 700;
    color: #3e4152;
  }
  .review-bar1 {
    width: 8.75rem;
    height: 0.3rem;
    background-color: #03a685;
  }
  /* ............................... */

  .check {
    /* border: lid red solid red; */
    padding: 0.8rem;
    background-color: #fff;
  }
  .check > h4 {
    font-size: 0.9rem;
  }
  .input {
    border: 1px solid #bfc0c6;
    display: flex;
    justify-content: space-between;
  }
  .input > input {
    padding: 0.5rem;
    width: 100%;
    border: none;
  }
  .input > button {
    border: none;
    width: 5rem;
    background-color: #fff;
    font-size: 0.89rem;
    padding: 0.5rem;
    font-weight: 600;
    color: rgb(191, 192, 198);
  }
  .check > ul {
    list-style: none;
    margin: 20px 0px;
  }
  .check > ul > li {
    margin-left: -2rem;
    color: #7e808c;
    font-size: 0.89rem;
  }
  /*__________________________ footer part_________________________________ */
  .Butns {
    width: 100%;
    /* border: lid red solid red; */
    background-color: #fff;
    padding: 0.8rem;
    z-index: 999;
    bottom: 0;
    position: fixed;
  }
  /* nishi */
  .Sub-btn {
    /* border: 2px solid blue; */
    /* width: 100% !important; */
    display:flex;
    justify-content: space-evenly !important;
    /* background-color: red !important; */

  }
  /* .addcartbtn{
    border: 2px solid !important;
  } */
  /* .buyitbtn{
    border: 2px solid !important;
  } */
  .Fbtn {
    width: 35%;

    /* border: lid red solid blue; */
  }
  .Fbtn > button {
    border: 1px solid grey;
    border-radius: 0.5rem;
    padding: 0.38rem;
    background-color: #fff;
    color: #3e4152;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
  }
  .Sbtn {
    /* border: lid red solid blue; */
    width: 55%;
  }
  /* .Sbtn > button {
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    color: #fff;
    background-color: #ff3e6c;
    border: 1px solid #ff2459;
  } */
  .Iconn {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Textt {
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
}
