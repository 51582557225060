@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Sevillana&family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Oriya:wght@100..900&family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans+Oriya:wght@100..900&family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans+Oriya:wght@100..900&family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cactus+Classical+Serif&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Gluten:wght@100..900&family=Noto+Sans+Oriya:wght@100..900&family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Cactus+Classical+Serif&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Gluten:wght@100..900&family=Noto+Sans+Oriya:wght@100..900&family=Noto+Serif+Gujarati:wght@100..900&family=Pacifico&family=Rock+Salt&family=Sevillana&family=Tangerine:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=Ga+Maamli&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Creepster&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Playball&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Creepster&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Creepster&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Playball&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Creepster&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Oswald:wght@200..700&family=Playball&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Creepster&family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Oswald:wght@200..700&family=Playball&family=Russo+One&family=Yeseva+One&display=swap");

.emptyimgcontainer {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 30%; */
  width: 100%;
}

.emptybuttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* margin-top: -30px; */
}

.btnhomepage {
  height: 35px;
  width: 150px;
  border: 1px solid #000;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  border-radius: 8px;
}

.btnhomepagetext {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

/* .backimgloading{
  background-image: url("../public/assets/images/banners/backbanner.png");
} */
.buttndesign {
  /* background-color: #f1c40f; */
  background-color: #f8b62d;
  width: 15vh;
  height: 3.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5vh;
  /* top: 300px; */
}

.buttndesign span {
  color: #000;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.endsell_on {
  display: flex;
  justify-content: flex-end;
  /* height: 3.5rem; */
  padding-right: 1rem;
}

#fixed_seller_on {
  top: 12rem;
  position: fixed;
  z-index: 9;
}

.search-parent {
  color: #ffff !important;
}

.categorynametext {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  padding-left: 6px;
  padding-top: 20px;
}

.categorynametext1 {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding-left: 10px;
  padding-top: 20px;
}

.hdr-icon {
  color: #fff !important;
}

.cart-count {
  background-color: #febd69;
  text-align: center !important;
}

.search {
  background-color: #febd69 !important;
  border-color: #febd69 !important;
  border-radius: 5px;
}

.search:hover {
  background-color: #e96f84 !important;
  border-color: #e96f84 !important;
  border-radius: 5px;
}

.input-text {
  border-radius: 5px 0px 0px 5px !important;
}

/* #2f415d */
.image_positions {
  position: relative;
}

.cat_position_div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6vh;
  background-color: #49506d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.cat_position_div span {
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  text-transform: capitalize;
}

.logo-img {
  width: 58%;
}

.error_show {
  border: 1px solid red;
  background-color: #ffe6e6;
}

.mobversionproductinfo {
  display: none;
}

.example {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

.example_new {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
}

.cursor_pointer {
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
}

.enlarged-image-container {
  /* Define styles for the enlarged image container */
}

.limited-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-height: 2em;
  line-height: 1em;
  -webkit-box-orient: vertical;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}

.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.bg1 {
  background: linear-gradient(45deg, #17449e, #000000cf);
}

.bg2 {
  background-color: aliceblue;
}

.homebannerimg {
  margin-top: 75px;
  height: 350px;
}

/* Nishitha----26-08-24 */

.togivegap {
  margin-top: 6rem;
}

.ant-drawer-mask {
  display: none !important;
}

:where(.css-j9bb5n).ant-drawer .ant-drawer-mask {
  display: none !important;
  /* background: #ffff !important; */
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-drawer .ant-drawer-mask {
  display: none !important;
}

.updatedaddressss {
  /* background-color: red; */
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.mobile_start_add {
  font-weight: 600 !important;
  color: #000 !important;
}

.number_input_mob {
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
}

#defaultaddresstochange {
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media screen and (max-width: 500px) {
  .ant-drawer-mask {
    display: block !important;
  }

  :where(.css-dev-only-do-not-override-j9bb5n).ant-drawer .ant-drawer-mask {
    display: flex !important;
  }

  :where(.css-j9bb5n).ant-drawer .ant-drawer-mask {
    display: block !important;
  }

  .container {
    margin-top: 0px !important;
  }

  .mobile_version_swipperr {
    display: block;
    height: 220px !important;
    width: 100%;
    margin-top: -70px !important;
  }

  .blockin_mobile_version {
    display: none;
  }
}

@media screen and (min-width: 720px) {
  .mobile_version_swipperr {
    display: none;
  }

  .blockin_mobile_version {
    display: block;
  }

  /* Nishitha.............🤞 */
  .container {
    margin-top: 15px !important;
  }

  .categorcontainer {
    margin-top: 100px !important;
  }

  #conatinerwomone_top {
    margin-top: -30px !important;
    /* background-color:red !important; */
  }

  .totaketop {
    margin-top: -15px !important;
  }

  .togivetop {
    margin-top: 20px !important;
  }

  /* Nishitha  End*/
  .address_mobile_footer {
    /* position: absolute; */
    /* bottom: 0;
    left: 0; */
    width: 100%;
    height: 6.5vh;
    background: #e96f84;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1vh;
  }
}

/* #incrementField{
  width:13rem !important;

} */
#bordershadow {
  padding: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* end------------- */

/* NIshitha */
@media screen and (min-width: 450px) {
  .ant-drawer-content-wrapper {
    display: none !important;
  }

  .css-8atqhb {
    display: none !important;
  }

  .add_new_mobi_btn {
    display: none !important;
  }

  /* emd */
}

@media screen and (max-width: 767px) {
  .togivegap {
    margin-top: 5rem !important;
  }

  .dexversionlogincontainer {
    display: none;
  }

  .homebannerimg {
    height: 100%;
    margin-top: 0px;
  }

  .catbannerdesk {
    margin-top: 40px;
  }

  .mobversionproductinfo {
    display: block;
  }

  .desktopversionproductinfo {
    display: none;
  }
}

@media screen and (min-width: 767px) {
  .mobileversionlogincontainer {
    display: none;
  }

  .mobversionproductinfo {
    display: block;
  }

  /* .desktopversionproductinfo{
    display: none;
  } */
}

/* Nishitha::"{{}}" */
/* #tochangethe_styles{
  border-radius:12px !important;
  border:1px solid #cccccc !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
} */

/* endddd */
.header_drowermobile {
  display: none;
}

.serchsugist {
  display: block;
  position: absolute;
  z-index: 9999;
  border: 1px solid #2f415d;
  background-color: #fff;
  width: 23.5%;
  padding: 0.5%;
  border-radius: 4px;
  max-height: 200px;
  /* overflow: scroll; */
  overflow-y: scroll;
  margin-top: 240px;
  right: 20%;
}

.serchsugist::-webkit-scrollbar {
  display: none;
}

.mobileVersion {
  display: none;
}

.leftbar-sticky {
  position: -webkit-sticky !important;
  /* For Safari */
  position: sticky !important;
  top: 50px;
  /* Distance from the top */
  left: 30px;
  /* Additional styles */
  z-index: 1000;
  /* Ensure it appears above other content if necessary */
}

.mensmobileversion {
  display: none;
}

.no_product_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.found_title {
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
}

.seller_mobile {
  display: none;
}

.hover_content {
  display: none;
}

.laptop_version_pages {
  display: block;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-drawer .ant-drawer-body {
  overflow: hidden;
  /* height: 100%; */
}

@media only screen and (max-width: 768px) {
  .footer {
    margin-bottom: 48px;
  }

  .serchsugist {
    display: block;
    position: absolute;
    z-index: 9999;
    border: 1px solid #2f415d;
    background-color: #fff;
    width: 24.5%;
    padding: 0.5%;
    border-radius: 4px;
    max-height: 200px;
    /* overflow: scroll; */
    overflow-y: scroll;
    /* nISHIATHa */
    /* margin-top:0px !important; */
    margin-top: 240px;
    right: 6%;
  }

  .serchsugist1 {
    display: block;
    position: absolute;
    z-index: 9999;
    border: 1px solid #2f415d;
    background-color: #fff;
    width: 24.5%;
    padding: 0.5%;
    border-radius: 4px;
    max-height: 200px;
    /* overflow: scroll; */
    overflow-y: scroll;
    /* nISHIATHa */
    /* margin-top:0px !important; */
    /* margin-top: 240px; */
    right: 11%;
  }

  .serchsugist2 {
    display: none;
  }

  /* nISHISS */
  /* #sub_orderpage{
  background-color:red !important;
} */

  /* .className="TitleHeading" */
  .TitleHeading {
    font-family: "Lora", serif !important;
    color: #315999;
    font-size: 1rem !important;
    font-weight: 600;
  }
  .offerprocont {
    margin-top: -30px;
  }
  .produbycont {
    margin-top: -55px;
  }
  .seller_icon {
    display: none !important;
  }

  .seller_mobile {
    display: block;
  }

  .endsell_on {
    display: none;
  }

  .desktopVersion {
    display: none;
  }

  .mobileVersion {
    display: block;
  }

  .mobileVersionaccount {
    display: none;
  }

  .mendextversion {
    display: none;
  }

  .mensmobileversion {
    display: block;
  }

  .cat_position_div {
    height: 4vh;
  }

  .cat_position_div span {
    font-size: 0.8rem;
  }

  .laptop_version_pages {
    display: none !important;
  }

  .mobile_version_pages {
    display: block;
    margin-top: 10vh;
  }

  .ant-drawer .ant-drawer-header {
    padding: 0 !important;
  }

  .header_drower {
    font-weight: 500;
    font-size: 1rem;
    color: #000;
  }

  .ant-drawer .ant-drawer-body {
    padding: 2.5vh !important;
  }
  /* rama changes */
  /* .ant-drawer-body{
    height: 200px !important;
  } */

  .input_box_mi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input_box_otp_mi {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .drawe_perha {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .buttondiv_drower {
    width: 100%;
    height: 8vh;
  }

  .disable_button_drow {
    width: 100%;
    height: 43px;
    background: #b6b8bc;
    border-radius: 0.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }

  .active_button_drow {
    width: 100%;
    height: 43px;
    background: #2f415d;
    border-radius: 0.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }

  .main_display_screen {
    width: 100%;
    height: 74.5vh;
    /* position: relative;
    overflow-y: scroll !important; */
  }

  .address_mobile_footer {
    /* position: absolute; */
    /* bottom: 0;
    left: 0; */
    width: 100%;
    height: 6.5vh;
    background: #2f415d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 9vh;
  }

  .add_new_mobi_btn {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    margin: 1vh 0;
    color: #000;
    font-size: 1.3rem;
    font-weight: 600;
    padding-left: 2vh;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 1vh 0;
  }

  .padding_ri {
    padding-right: 1vh;
  }

  .address_mob_list_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .address_mob_list_box {
    width: 99.5%;
    padding: 1vh;
    background: yellow;
    display: flex;
    flex-direction: row;
  }

  .add_mb_radio {
    width: 20vh;
    display: flex;
    justify-content: center;
  }

  .add_mb_address_dis {
    width: 80%;
    display: flex;
  }

  .ant-modal .ant-modal-content {
    padding: 10px !important;
  }
}

@media screen and (min-width: 768px) {
  .TitleHeading {
    font-family: "Ga Maamli", sans-serif !important;
    color: #315999;
    font-size: 2rem !important;
    font-weight: 600;
  }
}

.grid-products .item .product-box {
  max-height: 350px;
  display: flex;
  flex-direction: column;
}

.grid-products .item .product-image {
  max-height: 60%;
}

.grid-products .item .product-image img {
  min-height: 220px !important;
  object-fit: cover;
}

.mobile_icons {
  display: none;
}

.seller_hover_content {
  display: none;
}

.menu1 {
  display: none;
}
.mobile-version-to-top {
  margin-top: 21px !important;
}
@media screen and (min-width: 268px) and (max-width: 768px) {
  .deskmargin {
    margin-top: 1.5rem;
  }

  .togettop {
    margin-top: 30px;
  }

  .mobile-version-to-top {
    margin-top: -20px !important;
  }

  .mobversionproductinfo {
    display: block;
  }

  .desktopversionproductinfo {
    display: none;
  }

  .seller_hover_content {
    top: -5rem;
    left: -5rem;
    width: 8rem;
    /* left:100%; */
    position: relative;
    z-index: 800;
    display: none;
  }

  .seller_hover_content div {
    width: 100%;
    padding: 0;
  }

  .seller_hover_content span {
    width: 100%;
  }

  .topul,
  .bottonul {
    list-style: none;
    background-color: brown;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .topul .topli,
  .bottonul .bottomli {
    display: inline-block;
    position: relative;
  }

  .bottonul .bottomli .seller_hover_content {
    display: block;
    color: black;
    text-decoration: none;
    text-align: center;
    font-size: 30px;
  }

  .seller_hover_content {
    display: block;
  }

  .seller_hover_content {
    top: -5rem;
    left: -5rem;
    width: 8rem;
    /* left:100%; */
    position: relative;
    z-index: 800;
    display: none;
  }

  .seller_hover_content div {
    width: 100%;
    padding: 0;
  }

  .seller_hover_content span {
    width: 100%;
  }

  .topul .topli .seller_hover_content {
    width: 200px;
    height: 50px;
    background-color: blue;
    position: absolute;
    z-index: 999;
    display: none;
  }

  .topul .topli:hover .seller_hover_content {
    display: block;
  }

  .mobile_icons {
    display: block;
  }

  .hovering_Content {
    position: fixed;
    width: auto;
    height: 5rem;
    right: 0rem;
    top: 6rem;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* 
  .mobile_icons:hover+.seller_hover_content {
    display: flex;
  } */

  .mobile_version_container {
    width: 100% !important;
  }

  .row {
    width: 100% !important;
  }

  .search-bar {
    width: 100% !important;
  }

  /* .inputfiledsize {
    width: 20rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

  } */

  /* .mobile_navigation {
    width: 100% !important;
  } */

  .mobileversionwidth {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  #inputs_filed_mobile {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .mobile_version_container .mobile_row_version {
    width: 100% !important;
    padding: 0;
  }

  .container {
    width: 100% !important;
    padding: 5px;
    /* margin-top: 35px; */
  }

  .mobileVersion {
    width: 100%;
  }

  .searchField {
    width: 100% !important;
  }

  /* .container #AccessibleNav{
  width:100% !important;
}
.mobile_row_version{
  width:100%  !important;
}
.search-body{
  width:100% !important;
} */
  .mobile_input {
    width: 100% !important;
  }

  .mobile_input .input-text {
    width: 100% !important;
  }

  .inputs_filed_mobile {
    border-radius: 5px 0px 0px 5px !important;
  }

  .seller_icon {
    display: none !important;
  }

  .seller_mobile {
    display: block;
  }

  .header-1 .logo img {
    max-width: 120px;
    /* margin-left: -10px; */
  }

  .wishlist-link,
  .header-cart,
  .search-parent {
    display: none;
  }

  .grid-products .item .product-box {
    max-height: 350px;
    display: flex;
    flex-direction: column;
  }

  .grid-products .item .product-image {
    max-height: 60%;
  }

  .grid-products .item .product-image img {
    min-height: 220px !important;
    object-fit: cover;
    width: auto;
  }

  .input-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mensmobileversion {
    display: block;
  }

  .form .minisearch {
    width: 100%;
    height: 100%;
    background-color: blue;
  }

  .input-box input {
    height: 22px;
  }

  /* .search-body{
  margin-right: 30px;
} */
}

/* @media screen and (min-width :268px) and (max-width : 320px) {
  .inputfiledsize {
    width: 280px !important;
  }
} */

.category_heading {
  text-align: center;
  font-size: 1.2rem;
}

.categorymain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-color: red; */
  /* gap: 0.3rem; */
}

.Categorydiv {
  width: 33%;
  height: 7rem;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category_img {
  width: 4rem;
  height: 4rem;
  background-color: gainsboro;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Categorydiv p {
  text-align: center;
  height: 0.6rem;
}

.mainshirtscontainer {
  display: none;
  padding-top: 8px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.shirtscontainer {
  width: 50%;
  display: flex;
  align-items: center;
}

.shirtstext {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.itemstext {
  font-size: 10px;
  color: #666;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.2rem;
}

.cisearch {
  font-size: 48px;
  padding-right: 20px;
}

.bshandbag {
  font-size: 42px;
  padding-right: 20px;
}

.iconmaincontainer {
  display: flex;
  justify-content: space-between;
  /* margin-top: -10px; */
}

.footermaincontainer {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 999;
  display: none;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.footermaincontainer1 {
  /* padding: 3px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.searchmaincontainer {
  display: none;
}

.bisort {
  padding-right: 5px;
  font-size: 22px;
}

.iofilter {
  padding-right: 5px;
  font-size: 22px;
}

.sorttext {
  font-size: 14px;
  font-weight: 500;
}

.swipercategory {
  height: 180px;
  width: 90%;
}

.beautytext1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  margin-top: -10px;
  width: 100%;
}

.offernyka {
  /* padding: 10px; */
  margin-top: 20px;
  /* border: 1px solid black; */
}

.nykacard {
  width: 95%;
  margin-top: 10px;
  /* background-color: #f3f4f5; */
  /* padding: 20px; */
  margin-right: auto;
  margin-left: auto;
}

.forbrands {
  border-top: 1px solid #f6f5f2;
  border-bottom: 1px solid #f6f5f2;
  padding: 5px;
}

.brandNames:hover {
  cursor: pointer;
  box-shadow: -5px -5px 5px rgba(252, 252, 135, 0.6),
    5px 5px 5px rgba(252, 252, 135, 0.6), 5px -5px 5px rgba(252, 252, 135, 0.6),
    -5px 5px 5px rgba(252, 252, 135, 0.6) !important;
}

.nykacard .card {
  /* background-image: url('D:\Ongoing\Ews\Ews-Web-30-05-24\public\assets\images\z5.png'); */
  /* background-color: #775DA7; */
  border-radius: 10px;
  overflow: hidden;
  height: 220px;
  /* background-position: center; */
  background-size: 100%;
  width: 260px;
  /* background-color: \/; */
}

#cardtext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 5px; */
}

#cardtext p {
  font-size: 12px;
}

.card-parents {
  width: 175px;
}

.card-parents .card {
  width: 100%;
  /* background-color: navy !important; */
  background-size: 100%;
  background-repeat: no-repeat;
  /* background-image: none !important; */
  border: 1px solid #ccc;
  aspect-ratio: 3/2;
  object-fit: contain;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 200px;
  background-position: center;
  background-size: cover;
}

.nykacard .card img {
  height: 100%;
  width: 100%;
}

.nykacard .card p {
  vertical-align: bottom;
  margin-top: 20%;
  color: #fff;
  font-weight: 400;
}

#lorel {
  background-color: #b8adcf;
  position: absolute;
  font-weight: bold;
  width: 40%;
  border-bottom-right-radius: 10px;
  text-align: center;
  padding: 5px;
}

#lorel i {
  text-decoration: none;
  font-weight: 400;
}

.shop_img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  /* background-color: rebeccapurple; */
  border: 1.2px solid #f1c40f;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.shop_img > img {
  padding: 2px;
  /* display: none; */
}

.brandNames {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 2px solid #f1c40f;
  font-family: Anton ubuntu;
  font-size: 13px;
  text-align: center;
  color: #000;
}

.marktag {
  width: 130px;
  height: 25px;
}

.for_offerProducts {
  margin-top: -30px;
}

/*------------------------ajit---------------------*/

/* ul {
  list-style: none;
  background-color: brown;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul li {
  display: inline-block;
  position: relative;
}

ul li a {
  display: block;
  /* padding: 20px 25px; */

.mainaitworemove {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  /* border-top: 0.5px solid #ccc; */
}

.aitworemove {
  display: flex;
  /* padding: 5px; */
}

.aittext {
  padding-left: 5px;
  color: #666;
}

.aiticon {
  color: #666;
  font-size: 16px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #17449e;
}

.mobile-productinfo {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-productinfo {
    display: block;
  }

  .uppermob {
    border: 1px solid black;
    height: 54px;
  }

  .mobversionproductinfo {
    display: block;
  }

  .desktopversionproductinfo {
    display: none;
  }

  .top_z_index {
    z-index: 9999999 !important;
  }

  .cirlactive {
    background: black;
  }

  .cirlactive span {
    color: #fff !important;
    text-transform: uppercase;
  }
}
